import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { map } from 'rxjs/operators';

import { Product } from './product.model';
import * as config from '../shared/configs/global.config';

@Injectable({
    providedIn: 'root'
  })
export class ProductService {
    base_url: string = config.base_url;
    private _url:string = this.base_url+"/api"
    private _urlCost:string = this.base_url+"/api/costs"
    private _urlAnalytics:string = this.base_url+"/api/analytics"
    private _urlOtomoto:string = this.base_url+"/api/otomoto"
    product:Product;

    constructor(private http: HttpClient) {}

    getProducts(params:any=[]): Observable<Product[]> {
        //return this.http.get<Product[]>(this._url+`/products`, params);
        return this.http.get<Product[]>(this._url+`/products`);
    }
    getProductsList(params:any=[]) {
        return this.http.post(this._url+`/products/lists2`, params);
        //return this.http.get<Product[]>(this._url+`/products`);
    }
    getConfigProducts(vars:any=''): Observable<Product[]> {
        return this.http.post<Product[]>(this._url+`/products/lists`, vars);
    }
    getProductsCosts(): Observable<any[]> {
        return this.http.get<any[]>(this._url+`/productscosts`);
    }
    getProduct(id:string): Observable<Product[]> {
        return this.http.get<any>(this._url+`/products/`+id);
    }
    updateParams(params: any) {
        return this.http.post(this._url+'/products/updateparams', params);
    }
    createAdvert(params) {
        return this.http.post(this._url+'/products', params);
    }
    getCosts(): Observable<Product[]> {
        return this.http.get<Product[]>(this._urlCost);
    }
    getCostsList(product_id:number): Observable<any[]> {
        return this.http.get<any[]>(this._urlCost+'/lists/'+product_id);
    }
    getCost(id:number, product_id:number): Observable<any[]> {
        return this.http.get<any[]>(this._urlCost+'/show2/'+id+'/'+product_id);
    }
    createCosts(costs: any) {
        return this.http.post(this._urlCost, costs);
    }
    updateCosts(costs: any, id:number) {
        return this.http.post(this._urlCost+'/update2/'+id, costs);
    }
    deleteCost(id:number, product_id:number): Observable<any[]> {
        return this.http.get<any[]>(this._urlCost+'/delete/'+id+'/'+product_id);
    }

    getCostAnalysis(id:number): Observable<any[]> {
        return this.http.get<any[]>(this._urlCost+'/analysis/'+id);
    }
    
    getAnalytics(): Observable<any[]> {
        return this.http.get<any[]>(this._urlAnalytics);
    }
    getStatistics(): Observable<any[]> {
        return this.http.get<any[]>(this._urlAnalytics+'/statistics');
    }

    getMakeModel(): Observable<any[]> {
        return this.http.get<any>(this._url+`/products/makemodel`);
    }
    getVersion(category_id, brand_code, model_code): Observable<any[]> {
        return this.http.get<any[]>(this._urlOtomoto+'/version/'+category_id+'/'+brand_code+'/'+model_code);
    }

    getInsurance(params: any): Observable<any[]> {
        return this.http.post<any[]>(this._url+`/insurance/lists`, params);
    }
}
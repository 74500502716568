import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from '../../shared/data.service';

declare var $: any;
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public userData: any;
    public roles: number;
    public privilage: number;
    public motoplejada: number;
    public oc: number;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private dataService: DataService)
    {
        this.userData = this.dataService.getUserData();
        this.roles = this.userData.user.roles;
        this.privilage = this.userData.user.privilage;
        this.motoplejada = this.userData.user.settings.account_type;
        this.oc = this.userData.user.settings.oc;

        console.log('oc user', this.oc);

        if (this.userData.user.settings.account_type==1) {
            this.setCss();
            this.setLogoM();
        }
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        
        this.menuItems = ROUTES.filter(menuItem => {
            
            if (menuItem.oc==1 && (this.oc==0 && this.roles<2)) {
                console.log('OC: ', menuItem.title + ' - ' + menuItem.oc)
                return false;
            } else if (menuItem.oc==1) {
                return menuItem;
            }

            if (menuItem.motoplejada==0) {
                if (menuItem.roles==this.roles) {
                    if (this.privilage>=menuItem.privilage) return menuItem;
                }
            } else {
                if (menuItem.roles==this.roles && this.motoplejada==1) {
                    if (this.privilage>=menuItem.privilage) {
                        if (this.motoplejada==1) return menuItem;
                    }
                }
                
            }
            // if (menuItem.path=='/insurance' && this.userData.user.id==132) {
            //     return menuItem;
            // }
            if (this.roles>=2) {
                if (menuItem.roles==this.roles || menuItem.roles==0) return menuItem;
                if (this.roles==3) return menuItem;
            }
        });

        if (this.userData.user.settings.account_type==1) {
            this.setCss();
            this.setLogoM();
        }
    }

    setCss() {
        $("<link/>", {
            rel: "stylesheet",
            type: "text/css",
            href: "./assets/css/motoplejada.css"
         }).appendTo("head");
    }
    setLogoM() {
        $('.logo-navi').attr('src', './assets/img/logo-motoplejada.png');
    }
}

import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    {
        path: '/home', title: 'Tablica', icon: 'fa fa-home', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/products', title: 'Pojazdy', icon: 'fa fa-car', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/leads', title: 'Klienci', icon: 'fa fa-handshake-o', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: [] 
    },
    {
        path: '/finance', title: 'Rozliczenia', icon: 'fa fa-calculator', class: '', badge: '', badgeClass: '', roles: 1, privilage: 1, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/analytics', title: 'Analityka', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', roles: 1, privilage: 1, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/estimate', title: 'Koszty pojazdu', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/invoice', title: 'Faktury i dokumenty', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/insurance', title: 'Ubezpieczenia', icon: 'fa fa-life-ring', class: '', badge: '', badgeClass: '', roles: 3, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 1, submenu: []
    },
    {
        path: '/offers', title: 'Promocje', icon: 'fa fa-shopping-basket', class: '', badge: '', badgeClass: '', roles: 0, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/warranty', title: 'Gwarancje', icon: 'fa fa-list', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 1, oc: 0, submenu: []
    },
    {
        path: 'https://automania24.pl/zaloguj-sie', title: 'Części samochodowe', icon: 'fa fa-cogs', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: true, motoplejada: 1, oc: 0, submenu: []
    },
    {
        path: '/financing', title: 'Ofertowanie', icon: 'ft-zap', class: '', badge: '', badgeClass: '', roles: 2, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/summary', title: 'Rozliczenia', icon: 'fa fa-list', class: '', badge: '', badgeClass: '', roles: 3, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/bookkeeping', title: 'Księgowość', icon: 'fa fa-list', class: '', badge: '', badgeClass: '', roles: 3, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/partners', title: 'Dostawcy', icon: 'ft-zap', class: '', badge: '', badgeClass: '', roles: 3, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/regulamin', title: 'Regulamin', icon: 'fa fa-balance-scale', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    },
    {
        path: '/kontakt', title: 'Kontakt', icon: 'fa fa-phone', class: '', badge: '', badgeClass: '', roles: 1, privilage: 0, isExternalLink: false, motoplejada: 0, oc: 0, submenu: []
    }
];

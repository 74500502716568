import { Component, AfterViewChecked, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { NotifiService } from '../../service/notifi.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked, OnInit {

    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;
    public userData: any;
    public notification: any = [];

    constructor(private dataService: DataService, private notifiService: NotifiService) 
    {
        this.userData = this.dataService.getUserData();
        this.getNotifi();
    }

    ngOnInit() {
    }

    ngAfterViewChecked() {

        setTimeout(() => {
            var wrapperDiv = document.getElementsByClassName("wrapper")[0];
            var dir = wrapperDiv.getAttribute("dir");
            if (dir === 'rtl') {
                this.placement = 'bottom-left';
            }
            else if (dir === 'ltr') {
                this.placement = 'bottom-right';
            }
        }, 3000);


    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    getNotifi() {
        
        this.notifiService.getNotifi()
            .subscribe(data => {
                this.notification = data
        });
        
        setTimeout(()=>{
            this.getNotifi();
       }, 60000);
    }
}

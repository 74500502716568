//import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as config from '../../shared/configs/global.config';

@Injectable()
export class AuthService {
  token: string;
  base_url: string = config.base_url;

  constructor(private http: HttpClient) {}

  login(email: string, password: string) {

    return this.http.post<any>(this.base_url+`/api/user/login`, { email, password })
        .pipe(map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              //localStorage.setItem('token', JSON.stringify(user.token));
              localStorage.setItem('token', user.token);
              localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return user;
        }));
  }

  register(user: any) {
    return this.http.post<any>(this.base_url+`/api/user/register`, user)
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  logout() {
    localStorage.setItem('currentUser', '');
    this.token = null;
    //Todo localStorage
  }

  getToken2() {    
    return this.token;
  }

  isAuthenticated() {
    //TODO
    // here you can check if user is authenticated or not through his token 
    //console.log('isAuthenticated');
    //console.log(this.token);
    //console.log(localStorage.getItem('currentUser'));
    return true;
  }

}

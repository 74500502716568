import { Component, Injectable, Input, Output , OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NotificationSidebarComponent implements OnInit {

  public leadForm: FormGroup;

  open = false;
  public toggleN = false;
  message = "Nihil novi"

  constructor(private modalService: NgbModal, private  fb: FormBuilder) {
  }

  ngOnInit() {
    
    this.leadForm = this.fb.group({
      legal_form: '',
      phone: ''
    })
    //this.leadForm.controls['phone'].setValue('dupa2');
    $.getScript('./assets/js/notification-sidebar.js');
  }

  receiveMessage($event) {
  }

  toggle(product?:any) {
  }

  submit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.leadForm.controls; }

  // MODAL Poinformuj o co zapytac klienta
  openInfo(content) {
    this.modalService.open(content).result.then((result) => {
    }, (reason) => {
    });
  }


}

// export var base_url = 'http://localhost:8000';
// export var www_url =  'http://localhost:4200';
// export var www_url_api =  base_url;
export var base_url = 'https://api.aureus.pl';
export var www_url =  'https://app.aureus.pl';
export var www_url_api =  'https://api.aureus.pl';
export var api_handlowcy = 'https://crm.aureus.pl/api/app_handlowcy/lista/handlowcy';

//export var www_url =  www_url_api; // base_url; //'https://api.aureus.pl';

export var przelewy24: Array<any> = [{
    z24_id_sprzedawcy: '138532',
    crc: '6f2f68a7c183c21c'
}];

export var legal_form: Array<any> = [
    {
        id: 0,
        value: 'Osoba fizyczna'
    },
    {
        id: 1,
        value: '1 os. dział. gosp.'
    },
    {
        id: 2,
        value: 'Sp. cywilna'
    },
    {
        id: 6,
        value: 'Sp.jw.'
    },
    {
        id: 5,
        value: 'Sp. z o.o. / S.A.'
    },
    {
        id: 7,
        value: 'Rolnik'
    },
    {
        id: 3,
        value: 'Emeryt'
    },
    {
        id: 4,
        value: 'Rencista'
    }
];

export var privilage: Array<any> = [
    {
        id: 0,
        value: 'Widzi swoje'
    },
    {
        id: 1,
        value: 'Administrator'
    },
    {
        id: 2,
        value: 'Widzi wszystko'
    },
];

export var lead_status: Array<any> = [
    {
        id: 0,
        value: 'NIEAKTYWNY'
    },
    {
        id: 1,
        value: 'AKTYWNY'
    },
    {
        id: 2,
        value: 'NIEAKTYWNY'
    },
];

export var offer_status: Array<any> = [
    {
        id: 0,
        value: 'ARCHIWALNE'
    },
    {
        id: 1,
        value: 'AKTYWNE'
    }
];
export var features:Array<any> = [
    {'manual': 'manualna'},
    {'automatic': 'automatyczna'},
    {'dual-clutch': 'dwusprzęgłowa'},
    {'diesel': 'diesel'},
    {'petrol': 'benzyna'},
    {'petrol-lpg': 'benzyna + lpg'},
    {'cd': 'radio cd'},
    {'central-lock': 'centralny zamek'},
    {'front-electric-windows': 'przednie szyby elektryczne'},
    {'electronic-rearview-mirrors': 'elektryczne lusterka'},
    {'electronic-immobiliser': 'elektroniczny immobiliser'},
    {'front-airbags': 'przednie poduszki powietrzne'},
    {'front-passenger-airbags': 'przednie poduszki pasażera'},
    {'original-radio': 'fabryczne radio'},
    {'assisted-steering': 'wspomaganie kierownicy'},
    {'alarm': 'alarm'},
    {'alloy-wheels': 'aluminiowe felgi'},
    {'rear-parking-sensors': 'tylne czujniki parkowania'},
    {'rear-electric-windows': 'tylne szyby elektryczne'},
    {'esp': 'esp'},
    {'automatic-air-conditioning': 'klimatronik'},
    {'dual-air-conditioning': 'klimatyzacja dwustrefowa'},
    {'onboard-computer': 'komputer pokładowy'},
    {'side-window-airbags': 'boczne kurtyny powietrzne'},
    {'heated-rearview-mirrors': 'podgrzewane lusterka'},
    {'front-side-airbags': 'przednie kurtyny powietrzne'},
    {'fog-lights': 'światła przeciwmgłowe'},
    {'mp3': 'radio mp3'},
    {'isofix': 'isofix'},
    {'asr': 'asr'},
    {'front-heated-seats': 'podgrzewane przednie fotele'},
    {'bluetooth': 'radio bluetooth'},
    {'automatic-wipers': 'automatyczne wycieraczki'},
    {'automatic-lights': 'automatyczne światła'},
    {'panoramic-sunroof': 'panoramiczny dach'},
    {'electric-exterior-mirror': 'elektryczne lusterko zewnętrzne'},
    {'electric-interior-mirror': 'elektryczne lusterko wewnętrzne'},
    {'both-parking-sensors': 'przednie i tylne czujniki parkowania'},
    {'electric-adjustable-seats': 'elektrycznie ustawiane fotele'},
    {'leather-interior': 'skórzana tapicerka'},
    {'aux-in': 'radio aux'},
    {'usb-socket': 'gniazdo USB'},
    {'gps': 'nawigacja'},
    {'speed-limiter': 'ogranicznik prędkości'},
    {'cruise-control': 'tempomat'},
    {'steering-whell-comands': 'multifunkcyjna kierownica'},
    {'towing-hook': 'hak holowniczy'},
    {'head-display': 'head display'},
    {'tinted-windows': 'przyciemniane szyby'},
    {'roof-bars': 'relingi'},
    {'system-start-stop': 'system start-stop'},
    {'daytime-lights': 'światła dzienne'},
    {'leds': 'światła LED'},
    {'xenon-lights': 'światła XENON'},
    {'sd-socked': 'gniazdo SD'},
    {'rearview-camera': 'kamera tylna'},
    {'abs': 'abs'},
    {'gross': 'brutto'},
    {'net': 'netto'},
    {'heated-windshield': 'podgrzewana przednia szyba'},
    {'rear-passenger-airbags': 'tylne poduszki powietrzne'}
];

export var bank: Array<any> = [
    {
        id: 0,
        value: ''
    },
    {
        id: 1,
        value: 'Getin Noble Bank'
    },
    {
        id: 2,
        value: 'Idea Bank'
    },
    {
        id: 3,
        value: 'Santander Consumer Bank'
    },
    {
        id: 4,
        value: 'Idea Getin Leasing'
    },
    {
        id: 5,
        value: 'Idea Leasing'
    },
    {
        id: 6,
        value: 'PKO Leasing'
    },
    {
        id: 7,
        value: 'Idea Getin Leasing'
    },
    {
        id: 8,
        value: 'Idea Bank'
    },
    {
        id: 9,
        value: 'PKO Leasing'
    },
    {
        id: 10,
        value: 'Alior Leasing'
    },
    {
        id: 11,
        value: 'Alior Pożyczka'
    },
    {
        id: 12,
        value: ''
    },
    {
        id: 13,
        value: ''
    },
    {
        id: 14,
        value: 'Noble Finance'
    },
    {
        id: 15,
        value: 'Grenke Leasing'
    },
    {
        id: 16,
        value: 'PKO CFM'
    },
    {
        id: 17,
        value: 'ATHLON'
    },
    {
        id: 18,
        value: 'Santander Multirent'
    },
    {
        id: 19,
        value: 'Millenium Leasing'
    },
    {
        id: 20,
        value: 'Santander Multirent'
    },
    {
        id: 21,
        value: 'Noble Finance'
    }
];

export var agreement_status: Array<any> = [
    {
        id: 0,
        value: 'BRAK',//NOWY
        tooltip: 'Brak wniosku finansowego',
        color: 'grey'
    },
    {
        id: 1,
        value: 'NIEKOMPLETNY',
        tooltip: '',
        color: 'success'
    },
    {
        id: 2,
        value: 'OCZEKUJE NA DECYZJĘ',
        tooltip: '',
        color: 'success'
    },
    {
        id: 3,
        value: 'DECYZJA POZYTYWNA',
        tooltip: 'Pozytywna ocena zdolności Klienta',
        color: 'success'
    },
    {
        id: 4,
        value: 'ODROCZENIE',
        tooltip: '',
        color: 'success'
    },
    {
        id: 5,
        value: 'DECYZJA NEGATYWNA',
        tooltip: 'Negatywna ocena zdolności Klienta',
        color: 'success'
    },
    {
        id: 6,
        value: 'GENERACJA UMOWY',
        tooltip: '',
        color: 'success'
    },
    {
        id: 7,
        value: 'W URUCHOMIENIU',
        tooltip: '',
        color: 'success'
    },
    {
        id: 8,
        value: 'URUCHOMIONY',
        tooltip: '',
        color: 'success'
    },
    {
        id: 9,
        value: 'ANULOWANY',
        tooltip: '',
        color: 'success'
    },
    {
        id: 10,
        value: 'KOMPLETACJA UMOWY',
        tooltip: '',
        color: 'success'
    },
    {
        id: 11,
        value: 'BRAKI W UMOWIE',
        tooltip: '',
        color: 'success'
    },
    {
        id: 12,
        value: 'UMOWA KOMPLETNA',
        tooltip: '',
        color: 'success'
    },
    {
        id: 13,
        value: 'KLIENT ZREZYGNOWAŁ',
        tooltip: '',
        color: 'success'
    },
    {
        id: 14,
        value: 'KONTAKT Z DOK',
        tooltip: '',
        color: 'success'
    },
    {
        id: 15,
        value: 'ZGODA NA REJESTRACJĘ',
        tooltip: '',
        color: 'success'
    },
    {
        id: 16,
        value: '-',//UMOWA SPRAWDZONA
        tooltip: '',
        color: 'success'
    },
    {
        id: 17,
        value: '-',//'ZAMÓWIENIE DO DEALERA'
        tooltip: '',
        color: 'success'
    },
    {
        id: 18,
        value: 'REJESTRACJA',
        tooltip: '',
        color: 'success'
    },
    {
        id: 19,
        value: 'NOWY WNIOSEK',
        tooltip: '',
        color: 'success'
    },
    {
        id: 20,
        value: 'ROZLICZONO',
        tooltip: '',
        color: 'success'
    },
];

export var summary_status_lists: Array<any> = [
    {
        id: 0,
        value: 'UZNANIE'
    },
    {
        id: 1,
        value: 'OCZEKUJE NA FAKTURĘ'
    },
    {
        id: 2,
        value: 'POPRAW FAKTURĘ'
    },
    {
        id: 3,
        value: 'SKIEROWANO DO PŁATNOŚCI'
    },
    {
        id: 4,
        value: 'FAKTURA WYSŁANA'
    }
];

export var transmission_lists: Array<any> = [
    {
        id: 4,
        value: 'rear-wheel',
        pl: "Na tylne koła",
    },
    {
        id: 3,
        value: 'front-wheel',
        pl: "Na przednie koła",
    },
    {
        id: 0,
        value: 'all-wheel-auto',
        pl: "4x4 (dołączany automatycznie)",
    },
    {
        id: 1,
        value: 'all-wheel-lock',
        pl: "4x4 (dołączany ręcznie)",
    },
    {
        id: 2,
        value: 'all-wheel-permanent',
        pl: "4x4 (stały)",
    },

];

export var fuel_type_lists: Array<any> = [
    {
        id: 0,
        value: 'petrol',
        pl: "Benzyna",
    },
    {
        id: 1,
        value: 'diesel',
        pl: "Diesel",
    },
    {
        id: 2,
        value: 'petrol-lpg',
        pl: "Benzyna+LPG",
    },
    {
        id: 3,
        value: 'petrol-cng',
        pl: "Benzyna+CNG",
    },
    {
        id: 4,
        value: 'electric',
        pl: "Elektryczny",
    },
    {
        id: 5,
        value: 'etanol',
        pl: "Etanol",
    },
    {
        id: 6,
        value: 'hybrid',
        pl: "Hybryda",
    },
    {
        id: 7,
        value: 'hidrogen',
        pl: "Wodór",
    }
];

export var gearbox_lists: Array<any> = [
    {
        id: 0,
        value: 'manual',
        pl: "Manualna",
    },
    {
        id: 1,
        value: 'semi-automatic',
        pl: "Półautomatyczna (ASG, Tiptronic)",
    },
    {
        id: 2,
        value: 'automatic',
        pl: "Automatyczna hydrauliczna (klasyczna)",
    },
    {
        id: 3,
        value: 'cvt',
        pl: "Automatyczna bezstopniowa (CVT)",
    },
    {
        id: 4,
        value: 'dual-clutch',
        pl: "Automatyczna dwusprzęgłowa (DCT, DSG)",
    }
];

export var insurance_status: Array<any> = [
    {
        id: 0,
        value: 'PRZYSZŁA',
        tooltip: 'Polisa jeszcze nie jest aktywna',
        color: 'danger'
    },
    {
        id: 1,
        value: 'AKTYWNA',
        tooltip: 'Polisa aktywna',
        color: 'success'
    },
    {
        id: 2,
        value: 'WYGASŁA',
        tooltip: 'Polisa archiwalna',
        color: 'gray'
    },
    // {
    //     id: 3,
    //     value: 'ANULOWANA',
    //     tooltip: 'Polisa anulowana',
    //     color: 'gray'
    // }
];

export var insurance_type: Array<any> = [
    {
        id: 0,
        value: 'NOWA',
        tooltip: 'Nowa',
        color: 'success'
    },
    {
        id: 1,
        value: 'KALKULACJA',
        tooltip: 'Kalkulacja polisy',
        color: 'danger'
    },
    {
        id: 2,
        value: 'CZEKA NA PŁATNOŚĆ',
        tooltip: 'Przetwarzanie płatności',
        color: 'danger'
    },
    {
        id: 3,
        value: 'OPŁACONA',
        tooltip: 'Polisa opłacona',
        color: 'success'
    },
    // {
    //     id: 4,
    //     value: 'ANULOWANA',
    //     tooltip: 'Polisa anulowana',
    //     color: 'gray'
    // }
];
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';

import * as config from '../shared/configs/global.config';

@Injectable({
  providedIn: 'root'
})
export class NotifiService {
    
  base_url: string = config.base_url;
  www_url_api: string = config.www_url_api;
  notifiURL = this.base_url+"/api/notification";
  nid: any;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
    this.nid = this.activatedRoute.snapshot.queryParamMap.get('nid');
    if (this.nid) {
      this.setRead(this.nid);
    }
  }

  getNotifi() : Observable<any> {
    return this.http.get<any>(this.notifiURL);
  }
  
  getAllNotifi() : Observable<any> {
    return this.http.get<any>(this.notifiURL+'/all');
  }

  setRead(id) {
    this.http.get<any>(this.notifiURL+'/read/'+id)
    .subscribe(data => {
    });
  }
}
import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: './changelog/changelog.module#ChangeLogModule'
  },
  {
    path: 'products',
    loadChildren: './products/products.module#ProductsModule'
  },
  {
    path: 'clients',
    loadChildren: './clients/clients.module#ClientsModule'
  },
  {
    path: 'leads',
    loadChildren: './leads/leads.module#LeadsModule'
  },
  {
    path: 'aureus',
    loadChildren: './aureus/aureus.module#AureusModule'
  },
  {
    path: 'financing',
    loadChildren: './financing/financing.module#FinancingModule'
  },
  {
    path: 'offers',
    loadChildren: './offers/offers.module#OffersModule'
  },
  {
    path: 'home',
    loadChildren: './pages/full-layout-page/full-pages.module#FullPagesModule'
  },
  {
    path: 'profil',
    loadChildren: './profil/profil.module#ProfilModule'
  },
  {
    path: 'finance',
    loadChildren: './finance/finance.module#FinanceModule'
  },
  {
    path: 'analytics',
    loadChildren: './analytics/analytics.module#AnalyticsModule'
  },
  {
    path: 'invoice',
    loadChildren: './invoice/invoice.module#InvoiceModule'
  },
  {
    path: 'warranty',
    loadChildren: './warranty/warranty.module#WarrantyModule'
  },
  {
    path: 'estimate',
    loadChildren: './estimate/estimate.module#EstimateModule'
  },
  {
    path: 'summary',
    loadChildren: './summary/summary.module#SummaryModule'
  },
  {
    path: 'help',
    loadChildren: './help/help.module#HelpModule'
  },
  {
    path: 'parts',
    loadChildren: './parts/parts.module#PartsModule'
  },
  {
    path: 'partners',
    loadChildren: './partners/partners.module#PartnersModule'
  },
  {
    path: 'bookkeeping',
    loadChildren: './bookkeeping/bookkeeping.module#BookkeepingModule'
  },
  {
    path: 'notification',
    loadChildren: './notification/notification.module#NotificationModule'
  },
  {
    path: 'insurance',
    loadChildren: './insurance/insurance.module#InsuranceModule'
  },
  {
    path: 'regulamin',
    loadChildren: './regulation/regulation.module#RegulationModule'
  },
  {
    path: 'kontakt',
    loadChildren: './contact/contact.module#ContactModule'
  }
  
];
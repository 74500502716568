import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as config from '../shared/configs/global.config';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  base_url: string = config.base_url;
  private URL:string = this.base_url+"/api"
  private OtomotoURL:string = this.base_url+"/api/updateproducts"
  public userData:any;

  constructor(private http: HttpClient) {}

  getOtomoto() : Observable<any> {
    return this.http.get<any>(this.OtomotoURL);
}

  getExchange(): Observable<any> {
      return this.http.get<any>(this.URL + `/exchange`);
  }

  getData(text: string) {

  }

  getUserData() {
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    return this.userData;
  }

  dateFormat(date) {

    if (String(date['year'])!='') {

      let year = date['year'];
      let month = date['month'];
      let day = date['day'];

      if (String(month).length === 1 || month <= 9) {
        month = '0'+month;
      }
      if (String(day).length === 1 || day <= 9) {
        day = '0'+day;
      }

      return year+'-'+month+'-'+day;
    } else{
      return '';
    }
  }

  splitDate(date) {
    if (!date) return;
    let newDate = date.split('-');
    let objDate = {
      year: parseInt(newDate[0]),
      month: parseInt(newDate[1]),
      day: parseInt(newDate[2])
    }
    return objDate;
  }
  pricePL(wartosc)
  {
    let kwota = '';
    if (wartosc=='') return;
    kwota = wartosc;

      kwota = kwota.replace(',', '.');
      //kwota = parseFloat(kwota);
      let grosze;
      let zlote;
      let kwotaArr;
      let _kwota;
      kwotaArr = kwota.split('.');


      grosze = kwotaArr[1] + '/100';
      zlote = this._pricePL(kwotaArr[0]);
      _kwota = zlote;
      if (kwotaArr[1]!='' && kwotaArr[1]!=undefined) _kwota = _kwota + ' ' +  grosze;
      _kwota = _kwota + ' zł';
      return _kwota;
  }
  _pricePL(kwota)
  {
    // przypisanie obiektu pola tekstowego do zmiennej
    var pole_liczba = kwota;//document.forms["slowa"].liczba;
    // pobranie liczby
    //var liczba = pole_liczba.value;
    var liczba = parseFloat(kwota);

    var jednosci = ["", " jeden", " dwa", " trzy", " cztery", " pięć", " sześć", " siedem", " osiem", " dziewięć"];
    var nascie = ["", " jedenaście", " dwanaście", " trzynaście", " czternaście", " piętnaście", " szesnaście", " siedemnaście", " osiemnaście", " dziewietnaście"];
    var dziesiatki = ["", " dziesięć", " dwadzieścia", " trzydzieści", " czterdzieści", " pięćdziesiąt", " sześćdziesiąt", " siedemdziesiąt", " osiemdziesiąt", " dziewięćdziesiąt"];
    var setki = ["", " sto", " dwieście", " trzysta", " czterysta", " pięćset", " sześćset", " siedemset", " osiemset", " dziewięćset"];
    var grupy = [
    ["" ,"" ,""],
    [" tysiąc" ," tysiące" ," tysięcy"],
    [" milion" ," miliony" ," milionów"],
    [" miliard"," miliardy"," miliardów"],
    [" bilion" ," biliony" ," bilionów"],
    [" biliard"," biliardy"," biliardów"],
    [" trylion"," tryliony"," trylionów"]];
    
    if (!isNaN(liczba)){
    
    var wynik = '';
    var znak = '';

    if (liczba == 0)
      wynik = "zero";
    if (liczba < 0) {
      znak = "minus";
      liczba = liczba;
    }
    
    var g = 0;
    while (liczba > 0) {
      var s = Math.floor((liczba % 1000)/100);
      var n = 0;
      var d = Math.floor((liczba % 100)/10);
      var j = Math.floor(liczba % 10);

      if (d == 1 && j>0) {
        n = j;
        d = 0;
        j = 0;
      }
    
    var k = 2;
    if (j == 1 && s+d+n == 0)
      k = 0;
    if (j == 2 || j == 3 || j == 4)
      k = 1;
    if (s+d+n+j > 0)
      wynik = setki[s]+dziesiatki[d]+nascie[n]+jednosci[j]+grupy[g][k]+wynik;
      
      g++;
      liczba = Math.floor(liczba/1000);
    }
      //alert(znak + wynik);
      return znak + wynik;
    }
    else  {
      //return;
      pole_liczba.focus();
    }
    return;
  }
  
}
